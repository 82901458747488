import { GTMFormatedProduct } from './types'
import { ProductBannerGtm } from './types'

export const EMPTY_GTM_FORMATTED_PRODUCT: GTMFormatedProduct = {
    sku: '',
    name: '',
    price: '',
    thumbnail: ''
}

export const EMPTY_GTM_FORMATTED_BANNER: ProductBannerGtm = {
    banner_name: ''
}
